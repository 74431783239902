export const region = {
    getRegionForAdmin: {
        url: "getTribeRegions",
        method: "GET",
        params: null,
    },

    createRegionForAdmin: {
        url: "createOrUpdateTribeRegion",
        method: "POST",
        data: null,
    },
    deleteRegionForAdmin: {
        url: "deleteTribeRegion/",
        method: "DELETE",
        id: null,
    }

}